
li {
    list-style-type:none;
}

.blueInfoHeader{
    color:#003267;
    padding:0px;
    margin:0px !important;
}
.pinBottom { 
    height: 50px; 
    border-top: 2px solid #003267;
    padding: 5px;
    text-align: center;
    position: fixed; 
    background-color: white;
    bottom:0%;
    width:100%; 
    opacity: 1;
}

.sectionQuestion{
    width:80%;
    margin-left: 2%;
    margin-right: 2%;
}

.sectionNumber  {
    width:120px;
    display: inline-flex;
    text-align:center;
    height:100%;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #003267;
    padding: 0 5px;
    margin-right: 2%;
    float:left;
}
.selectTools{
    width:20px;
    display: inline-flex;
    text-align:center;
    height:100%;
    font-size: 16px;
    font-weight: bold;
    color: white;
    margin-right: 1%;   
    float:left;
}
.responseTools{
    width:20px;
    display: inline-flex;
    text-align:center;
    height:100%;
    font-size: 16px;
    font-weight: bold;
    color: white;
    margin-right: 2%;   
    float:left;
}

.questionTools{
    float:left;
    width:60%;
    display: contents;
}

.sectionTools{
    float:right;
    width:120px;
    display: inline-flex;
    text-align:center;
    height:100%;
    font-size: 16px;
    font-weight: bold;
    color: white;
    margin-right: 2%;
}
.tool{
    color:#28a745;
}
.tool:hover{
    color:#003267;
}

.sectionStyle {
    color: white;
    background-color: #003267;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 0px;
    padding-left: 18px;
}

.sectionHeading{
    font-size:14px;
    font-weight: 700;
}
.sectionHeadingTools{
    float: right;
}
.sectionHeadingDeleteTools{
    color: orangered;
    margin-left:20px;
}
.sectionHeadingToolsMove{
    float: right;
    margin-right: 20px;
    margin-top: 3px;
}
.sectionArrows{
    width:100px;
}
.sectionColor {
    color: white;
    background-color: #003267;
    padding: 0 5px;
    margin-right: 5px;
}

.iconStyleTools{
    color:#fff;
    margin-right: 20px;
}
.iconStyleAdd{
    color:#fff;
    margin-right: 20px;
}
.iconStyleAdd:hover{
    color:#28a745;

}
.iconStyleTools:hover{
    color:red;
}
.iconStyleOverall{
    color:red;
}
.iconStyle {
    color: #AA4465;
    margin-top: 5px;
    margin-right:5px;
}
.iconStyle:hover{
    color: red;
}
.sectionHeadingToolsItem{
    display:inline-block;
    width:30px;
}

.questionStyle {
    color: #AA4465;
    background-color: #EDF0DA;
    padding-top:10px;
    padding-bottom: 10px;
    padding-left: 15px;
    margin-top: 5px;
}

.newQuestionStyle {
    color: #AA4465;
    background-color: #4D5759;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.sections{
    margin-bottom:1px;
}

.sectionTop{
    background-color: #1e7e34;
   
}
.sections:last-child{
    padding-bottom: 50px;
}

.listBackground{
    background-color: #EDF0DA;
}
ul{
    margin-left: -40px;
}

.blueFlag{
    border: blue solid;    
}
.greenFlag{
    border: green solid;
}
.yellowFlag{
    border: yellow solid;
}
.redFlag{
    border: red solid;
}

.addQuestionHeader{
    color: #fff;
    background-color: #AA4465;
    height:85px;
    padding: 10px;
}
.addQuestionList{
    margin: 0px auto;
    color: #AA4465;
    background-color: #EDF0DA;
    padding: 10px 10px;
    border: 1px solid #AA4465;
    margin: 2px 0px;
}
.addQuestionFooter{
    color: #fff;
    background-color: #AA4465;
    height:50px;
    padding: 10px;
}
.addQuestionItem{
    color: #000;
}
.buttonFormat{
    margin-top: 35px;
}
.surveyTitleInfo{
    color:#fff;
    float: left;
    font-size: 24px;
    display: contents;

}
.surveyEffectiveDateInfo{
    color:#003267;
    float:left;
    font-size: 16px;

}
.surveyQualtricsIdInfo{
    color:#003267;
    font-size: 16px;
    text-align: left;
}
.surveyNewSectionButton{
    float: right;
}

.editSectionForm{
    width:400px;
    display: inline-flex;
}
.modalBG{
    background-color: #EDF0DA;
}
.questionModal{
    width: 90vw;
    max-width: 90vw !important;

}
.questionModalEdit{
    width: 90vw;
    max-width: 90vw !important;
    min-height: 500px;
}
.popTitleStyle {
    color: #497a4f;
    width:250px;
}