.ResponseDownload{
    color: #AA4465;
    background-color: #EDF0DA;
    padding: 35px 0px;
}

.surveySelect{
    font-size: 12px !important;
}

.successToast{ 
    position: absolute;
    top: 0;
    right: 0;
}

.slidePaneMenu {
    background-color: #003267 !important;
    color: #fff !important;
    z-index: 9999 !important;
}

.slide-pane__header {
    background-color: #003267 !important;
}
.slide-pane__title-wrapper {
    margin-left: 0 !important;
}

.badge-danger{
    font-size: 20px !important;
    cursor: pointer;
}
.badge-success{
    font-size: 20px !important; 
}