.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo{
  width:350px;
}

.navbar{
  background-color: #fff !important;
}
.report{
  background-color: #fff !important;
}

.nav-link{
margin-right: 20px;
}
.nav-link{
font-size: 24px;
}

.card-body{
  padding: 0px !important;
}

body {
  margin: 0;            /* Reset default margin */
}
iframe {
  display: block;       /* iframes are inline by default */
  background: #000;
  border: none;         /* Reset default border */
  height: 100vh;        /* Viewport-relative units */
  width: 100vw;
}

.alert-shown {
  opacity: 1;
  display: block;
  transition: all 250ms linear;
}

.alert-hidden {
  opacity: 0;
  display: none;
  transition: all 250ms linear;
}


/* -- Survey Builder  */
.sectionTools{
  text-align: right;
}

.sectionQuestion{
    width:80%;
    margin-left: 2%;
    margin-right: 2%;
}
.sectionTool{
    width:10%;
}
.sectionNumber{
    width:5%;
}
[draggable] {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    /* Required to make elements draggable in old WebKit */
    -khtml-user-drag: element;
    -webkit-user-drag: element;
}

.iconStyle {
    color: #AA4465;
    margin-top: 5px;
}

.popTitleStyle {
    color: #497a4f;
}

.sectionStyle {
    color: white;
    background-color: #003267;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 0px;
}

.sectionColor {
    color: white;
    background-color: #003267;
    padding: 0 5px;
    margin-right: 5px;
}

.sectionNumber  {
    width:10%;
    text-align:center;
    height:100%;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #003267;
    padding: 0 5px;
    margin-right: 2%;
}

 .questionStyle {
    color: #AA4465;
    background-color: #EDF0DA;
    padding-top:10px;
    padding-bottom: 10px;
    padding-left: 15px;
    margin-top: 5px;
}

.newQuestionStyle {
    color: #AA4465;
    background-color: #4D5759;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.toolColor {
    width: 50px;
    color: white;
    background-color: #fff;
}

.addQuestionStyle{
    margin-top:25px;
}

.addedClass{
  background-color:#497a4f;
}
.deletedClass{
  background-color:#AA4465;
}

.thresholdLabel{
  display:block !important;
  margin-bottom: 15px !important;
  height: 40px !important;
}
.dateLabel{
  width:275px;
}
.blueInfoHeader{
  color:#003267;
  padding:0px;
  margin:0px !important;
}
.pinBottom { 
  height: 65px; 
  border-top: 2px solid #003267;
  padding: 5px;
  text-align: center;
  position: fixed; 
  background-color: white;
  bottom:0%;
  width:100%; 
  opacity: 1;
}
/* -- End Survey Builder  */