.centerColumn{
    text-align:left;
}
.serviceSpan{
    font-size: 18px;
    display: block;
    margin-bottom: 10px;
}
.serviceRow:first-child{
    margin-bottom: 10px;
}
.serviceRow{
    margin-bottom: 50px;
}

.serviceDate{
    width:100px;
}

#clientTab .nav-link{
    font-size:14px !important;
}

.selectWidth{
    min-width:150px;
    max-width:250px;
}