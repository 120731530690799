.rowColor{
    margin: 0px !important;
    align-items: center;
    padding: 0.5rem 0 0.5rem 0;
}

.rowColor:nth-child(odd){
    background-color: #dee2e6;
}


li {
    list-style-type:none;
}

.blueInfoHeader{
    color:#003267;
    padding:0px;
    margin:0px !important;
}
.pinBottom { 
    height: 50px; 
    border-top: 2px solid #003267;
    padding: 5px;
    text-align: center;
    position: fixed; 
    background-color: white;
    bottom:0%;
    width:100%; 
    opacity: 1;
}

.sectionQuestion{
    width:80%;
    margin-left: 2%;
    margin-right: 2%;
}

.sectionNumber  {
    width:120px;
    display: inline-flex;
    text-align:center;
    height:100%;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #003267;
    padding: 0 5px;
    margin-right: 2%;
    float:left;
}
.selectTools{
    width:20px;
    display: inline-flex;
    text-align:center;
    height:100%;
    font-size: 16px;
    font-weight: bold;
    color: white;
    margin-right: 1%;   
    float:left;
}
.responseTools{
    width:20px;
    display: inline-flex;
    text-align:center;
    height:100%;
    font-size: 16px;
    font-weight: bold;
    color: white;
    margin-right: 2%;   
    float:left;
}

.questionTools{
    float:left;
    width:60%;
    display: contents;
}

.sectionTools{
    float:right;
    width:120px;
    display: inline-flex;
    text-align:center;
    height:100%;
    font-size: 16px;
    font-weight: bold;
    color: white;
    margin-right: 2%;
}
.tool{
    color:#28a745;
}
.tool:hover{
    color:#003267;
}

.sectionStyle {
    color: white;
    background-color: #003267;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 0px;
    padding-left: 18px;
}

.sectionHeading{
    font-size:14px;
    font-weight: 700;
}
.sectionHeadingTools{
    float: right;
}
.sectionHeadingDeleteTools{
    color: orangered;
    margin-left:20px;
}
.sectionHeadingToolsMove{
    float: right;
    margin-right: 20px;
    margin-top: 3px;
}
.sectionArrows{
    width:100px;
}
.sectionColor {
    color: white;
    background-color: #003267;
    padding: 0 5px;
    margin-right: 5px;
}

.iconStyleTools{
    color:#fff;
    margin-right: 20px;
}
.iconStyleAdd{
    color:#fff;
    margin-right: 20px;
}
.iconStyleAdd:hover{
    color:#28a745;

}
.iconStyleTools:hover{
    color:red;
}
.iconStyleOverall{
    color:red;
}
.iconStyle {
    color: #AA4465;
    margin-top: 5px;
    margin-right:5px;
}
.iconStyle:hover{
    color: red;
}
.sectionHeadingToolsItem{
    display:inline-block;
    width:30px;
}

.questionStyle {
    color: #AA4465;
    background-color: #EDF0DA;
    padding-top:10px;
    padding-bottom: 10px;
    padding-left: 15px;
    margin-top: 5px;
}

.newQuestionStyle {
    color: #AA4465;
    background-color: #4D5759;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.sections{
    margin-bottom:1px;
}

.sectionTop{
    background-color: #1e7e34;
   
}
.sections:last-child{
    padding-bottom: 50px;
}

.listBackground{
    background-color: #EDF0DA;
}
ul{
    margin-left: -40px;
}

.blueFlag{
    border: blue solid;    
}
.greenFlag{
    border: green solid;
}
.yellowFlag{
    border: yellow solid;
}
.redFlag{
    border: red solid;
}

.addQuestionHeader{
    color: #fff;
    background-color: #AA4465;
    height:85px;
    padding: 10px;
}
.addQuestionList{
    margin: 0px auto;
    color: #AA4465;
    background-color: #EDF0DA;
    padding: 10px 10px;
    border: 1px solid #AA4465;
    margin: 2px 0px;
}
.addQuestionFooter{
    color: #fff;
    background-color: #AA4465;
    height:50px;
    padding: 10px;
}
.addQuestionItem{
    color: #000;
}
.buttonFormat{
    margin-top: 35px;
}
.surveyTitleInfo{
    color:#fff;
    float: left;
    font-size: 24px;
    display: contents;

}
.surveyEffectiveDateInfo{
    color:#003267;
    float:left;
    font-size: 16px;

}
.surveyQualtricsIdInfo{
    color:#003267;
    font-size: 16px;
    text-align: left;
}
.surveyNewSectionButton{
    float: right;
}

.editSectionForm{
    width:400px;
    display: inline-flex;
}
.modalBG{
    background-color: #EDF0DA;
}
.questionModal{
    width: 90vw;
    max-width: 90vw !important;

}
.questionModalEdit{
    width: 90vw;
    max-width: 90vw !important;
    min-height: 500px;
}
.popTitleStyle {
    color: #497a4f;
    width:250px;
}
.centerColumn{
    text-align:left;
}
.serviceSpan{
    font-size: 18px;
    display: block;
    margin-bottom: 10px;
}
.serviceRow:first-child{
    margin-bottom: 10px;
}
.serviceRow{
    margin-bottom: 50px;
}

.serviceDate{
    width:100px;
}

#clientTab .nav-link{
    font-size:14px !important;
}

.selectWidth{
    min-width:150px;
    max-width:250px;
}
.ResponseDownload{
    color: #AA4465;
    background-color: #EDF0DA;
    padding: 35px 0px;
}

.surveySelect{
    font-size: 12px !important;
}

.successToast{ 
    position: absolute;
    top: 0;
    right: 0;
}

.slidePaneMenu {
    background-color: #003267 !important;
    color: #fff !important;
    z-index: 9999 !important;
}

.slide-pane__header {
    background-color: #003267 !important;
}
.slide-pane__title-wrapper {
    margin-left: 0 !important;
}

.badge-danger{
    font-size: 20px !important;
    cursor: pointer;
}
.badge-success{
    font-size: 20px !important; 
}
.sdRowCenter{
    margin: 0px auto;
    width:600px;
    background-color: #1a356b;
    margin-bottom: 20px;
    padding:10px;
    border-radius: 25px;
    color: white;
}
.buttonPlan{
    margin-bottom: 20px;
    margin-left: -40px;
    margin-right:50px;
}
.buttonMove{
    margin-left: 0px;
    margin-right:20px; 
}
.buttonSelect{
    width:300px; 
    margin: 0px auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
.centerText{
    text-align: center;
    margin-bottom: 20px;
}

.centerItem{
    text-align: center;
}

.surveySelect{
    width:100%; 
    font-size: 14px;
    color: black;
    margin-bottom: 20px;
}
.operationSelect{
    width:150px; 
    font-size: 14px;
    color: black;
    margin-bottom: 20px;
    float:left;
}

.controlPanel{
    width: 80px;
    position: absolute;
    top: 80px;
    left: 15px;
}
.dot {
    height: 75px;
    width: 75px;
    background-color: #1a356b;
    border-radius: 50%;
    display: inline-block;
    margin: 0px auto;
    text-align: center;
    margin-bottom: 25px;
}
.dot:hover{
    background-color: white;
}

.dot .iconStyle{
    color:#e3b73b;
    display: inline-block;
}

.upperLine{
    border-top: 1px solid white;
    margin-top: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
}
.lowerLine{
    border-top: 1px solid #1a356b;
    margin-top: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
}

.selectionPlanRow{
    height: 400px;
    max-height: 400px;
}

.toast {
    margin-top: 20px;
    max-width: 200px;
    width: 200px !important;
    height:100px;
    max-height: 100px;
    overflow: hidden;
    float: left !important;
    margin-left:10px;
}
.distroLabel{
    text-align: right;
}
.mainRow{
    margin-bottom: 25px;
}

.buttonPad{
    margin-right: 10px;
}

.list-type4{
    width:410px;
    margin:0 auto;
    }
    
    .list-type4 ol{
    counter-reset: li;
    list-style: none;
    *list-style: decimal;
    font-size: 15px;
    font-family: 'Raleway', sans-serif;
    padding: 0;
    margin-bottom: 4em;
    }
    .list-type4 li{
    position: relative;
    display: block;
    padding: .4em .4em .4em 2em;
    *padding: .4em;
    margin: .5em 0;
    background: #1a356b;
    color: white;
    text-decoration: none;
    box-shadow:inset 0.5em 0 #e3b73b;
    -webkit-transition: box-shadow 1s; /* For Safari 3.1 to 6.0 */
    transition: box-shadow 1s;
    }

    .list-type4 a:hover{
    box-shadow:inset 2em 0 black;
    }

    input[type=checkbox]{
     /* Double-sized Checkboxes */
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    padding: 25px;
    margin-left:30px !important;
    margin-right: 20px !important
}

.listItemButton{
    float:right;
    width: 20px;
    height: 20px;
    font-size: 10px !important;
    padding: 0px !important;
    line-height:100px;
    margin: auto;
    text-align: center;
    vertical-align: middle !important;
}
.centerFormCheck{
    margin: 0px auto;
    padding-top: 25px;
}

.contactPlanRow{
    margin-top:50px;
}

.react-datepicker-wrapper{
    width:100px !important;
}

.react-datepicker-wrapper input[type=text]{
 width: 75px;
}

.numberBox{
    width:75px !important;
}
.timeSelect{
    width:100px;
   
}

.messageName{
    margin-bottom: 25px;
}

.controlText{
    margin-right: 10px;
}

.react-datepicker-wrapper input[type=text] {
    width: 175px;
    padding-left: 10px;
    margin-left:20px;
}

.block{
    display: block;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.logo{
  width:350px;
}

.navbar{
  background-color: #fff !important;
}
.report{
  background-color: #fff !important;
}

.nav-link{
margin-right: 20px;
}
.nav-link{
font-size: 24px;
}

.card-body{
  padding: 0px !important;
}

body {
  margin: 0;            /* Reset default margin */
}
iframe {
  display: block;       /* iframes are inline by default */
  background: #000;
  border: none;         /* Reset default border */
  height: 100vh;        /* Viewport-relative units */
  width: 100vw;
}

.alert-shown {
  opacity: 1;
  display: block;
  -webkit-transition: all 250ms linear;
  transition: all 250ms linear;
}

.alert-hidden {
  opacity: 0;
  display: none;
  -webkit-transition: all 250ms linear;
  transition: all 250ms linear;
}


/* -- Survey Builder  */
.sectionTools{
  text-align: right;
}

.sectionQuestion{
    width:80%;
    margin-left: 2%;
    margin-right: 2%;
}
.sectionTool{
    width:10%;
}
.sectionNumber{
    width:5%;
}
[draggable] {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    /* Required to make elements draggable in old WebKit */
    -khtml-user-drag: element;
    -webkit-user-drag: element;
}

.iconStyle {
    color: #AA4465;
    margin-top: 5px;
}

.popTitleStyle {
    color: #497a4f;
}

.sectionStyle {
    color: white;
    background-color: #003267;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 0px;
}

.sectionColor {
    color: white;
    background-color: #003267;
    padding: 0 5px;
    margin-right: 5px;
}

.sectionNumber  {
    width:10%;
    text-align:center;
    height:100%;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #003267;
    padding: 0 5px;
    margin-right: 2%;
}

 .questionStyle {
    color: #AA4465;
    background-color: #EDF0DA;
    padding-top:10px;
    padding-bottom: 10px;
    padding-left: 15px;
    margin-top: 5px;
}

.newQuestionStyle {
    color: #AA4465;
    background-color: #4D5759;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.toolColor {
    width: 50px;
    color: white;
    background-color: #fff;
}

.addQuestionStyle{
    margin-top:25px;
}

.addedClass{
  background-color:#497a4f;
}
.deletedClass{
  background-color:#AA4465;
}

.thresholdLabel{
  display:block !important;
  margin-bottom: 15px !important;
  height: 40px !important;
}
.dateLabel{
  width:275px;
}
.blueInfoHeader{
  color:#003267;
  padding:0px;
  margin:0px !important;
}
.pinBottom { 
  height: 65px; 
  border-top: 2px solid #003267;
  padding: 5px;
  text-align: center;
  position: fixed; 
  background-color: white;
  bottom:0%;
  width:100%; 
  opacity: 1;
}
/* -- End Survey Builder  */
