.sdRowCenter{
    margin: 0px auto;
    width:600px;
    background-color: #1a356b;
    margin-bottom: 20px;
    padding:10px;
    border-radius: 25px;
    color: white;
}
.buttonPlan{
    margin-bottom: 20px;
    margin-left: -40px;
    margin-right:50px;
}
.buttonMove{
    margin-left: 0px;
    margin-right:20px; 
}
.buttonSelect{
    width:300px; 
    margin: 0px auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
.centerText{
    text-align: center;
    margin-bottom: 20px;
}

.centerItem{
    text-align: center;
}

.surveySelect{
    width:100%; 
    font-size: 14px;
    color: black;
    margin-bottom: 20px;
}
.operationSelect{
    width:150px; 
    font-size: 14px;
    color: black;
    margin-bottom: 20px;
    float:left;
}

.controlPanel{
    width: 80px;
    position: absolute;
    top: 80px;
    left: 15px;
}
.dot {
    height: 75px;
    width: 75px;
    background-color: #1a356b;
    border-radius: 50%;
    display: inline-block;
    margin: 0px auto;
    text-align: center;
    margin-bottom: 25px;
}
.dot:hover{
    background-color: white;
}

.dot .iconStyle{
    color:#e3b73b;
    display: inline-block;
}

.upperLine{
    border-top: 1px solid white;
    margin-top: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
}
.lowerLine{
    border-top: 1px solid #1a356b;
    margin-top: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
}

.selectionPlanRow{
    height: 400px;
    max-height: 400px;
}

.toast {
    margin-top: 20px;
    max-width: 200px;
    width: 200px !important;
    height:100px;
    max-height: 100px;
    overflow: hidden;
    float: left !important;
    margin-left:10px;
}
.distroLabel{
    text-align: right;
}
.mainRow{
    margin-bottom: 25px;
}

.buttonPad{
    margin-right: 10px;
}

.list-type4{
    width:410px;
    margin:0 auto;
    }
    
    .list-type4 ol{
    counter-reset: li;
    list-style: none;
    *list-style: decimal;
    font-size: 15px;
    font-family: 'Raleway', sans-serif;
    padding: 0;
    margin-bottom: 4em;
    }
    .list-type4 li{
    position: relative;
    display: block;
    padding: .4em .4em .4em 2em;
    *padding: .4em;
    margin: .5em 0;
    background: #1a356b;
    color: white;
    text-decoration: none;
    box-shadow:inset 0.5em 0 #e3b73b;
    -webkit-transition: box-shadow 1s; /* For Safari 3.1 to 6.0 */
    transition: box-shadow 1s;
    }

    .list-type4 a:hover{
    box-shadow:inset 2em 0 black;
    }

    input[type=checkbox]{
     /* Double-sized Checkboxes */
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    padding: 25px;
    margin-left:30px !important;
    margin-right: 20px !important
}

.listItemButton{
    float:right;
    width: 20px;
    height: 20px;
    font-size: 10px !important;
    padding: 0px !important;
    line-height:100px;
    margin: auto;
    text-align: center;
    vertical-align: middle !important;
}
.centerFormCheck{
    margin: 0px auto;
    padding-top: 25px;
}

.contactPlanRow{
    margin-top:50px;
}

.react-datepicker-wrapper{
    width:100px !important;
}

.react-datepicker-wrapper input[type=text]{
 width: 75px;
}

.numberBox{
    width:75px !important;
}
.timeSelect{
    width:100px;
   
}

.messageName{
    margin-bottom: 25px;
}

.controlText{
    margin-right: 10px;
}

.react-datepicker-wrapper input[type=text] {
    width: 175px;
    padding-left: 10px;
    margin-left:20px;
}

.block{
    display: block;
}